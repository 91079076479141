@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    background-color: black;
}

.App {
    background-color: black;
}

.homeImage {
    height: 50vh;
    width: auto;
    margin-top: 100px;
}

.home-text {
    margin-top: 50px;
}

.caret {
    position: absolute;
    margin-top: 95vh;
}

.song-overlay {
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 90%);
   -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 90%);
}